exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-views-recap-template-tsx": () => import("./../../../src/views/Recap/Template.tsx" /* webpackChunkName: "component---src-views-recap-template-tsx" */),
  "component---src-views-rewards-template-tsx": () => import("./../../../src/views/Rewards/Template.tsx" /* webpackChunkName: "component---src-views-rewards-template-tsx" */),
  "component---src-views-staking-kiln-template-tsx": () => import("./../../../src/views/Staking/Kiln/Template.tsx" /* webpackChunkName: "component---src-views-staking-kiln-template-tsx" */),
  "component---src-views-staking-stakewise-template-tsx": () => import("./../../../src/views/Staking/Stakewise/Template.tsx" /* webpackChunkName: "component---src-views-staking-stakewise-template-tsx" */),
  "component---src-views-staking-verse-template-tsx": () => import("./../../../src/views/Staking/Verse/Template.tsx" /* webpackChunkName: "component---src-views-staking-verse-template-tsx" */)
}

